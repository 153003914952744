import { useParams } from "react-router";
import { Button, Input, InputNumber, Select, SelectProps, Space } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBrain, faMicrochip } from "@fortawesome/free-solid-svg-icons";
import { ReactNode, useEffect, useRef, useState } from "react";

import { ClusterRebalanceConfiguration } from "../type/rebalance";
import { rebalance } from "../api/rebalance";
import { loading } from "../components/Loading";
import CloudPilotSpin from "../components/Spin";
import { configNodeProvisionMaxValues, configNodeProvisionMinValues, extractGeneralNodeProvisionMaxMemoryMiB,
    configNodeProvisionInstanceFamily, configNodeProvisionSystemDiskSize, extractGeneralNodeProvisionMinMemoryMiB,
    configExtraAllocation, extractNodeProvisionInstanceFamily, extractGPUNodeProvisionMinGPUMemoryMiB,
    extractGPUNodeProvisionMinGPUCards, extractExtraResourceAllocation, extractGeneralNodeProvisionSystemDiskSizeGiB,
    extractGeneralNodeProvisionMaxCPUCores, extractGeneralNodeProvisionMinCPUCores, 
    configNodeProvisionInstanceTags, extraNodeProvisionInstanceTags, extractGeneralNodeProvisionInstanceArchitecture,
    configNodeProvisionInstanceArch } from "../utils/rebalance";
import { cpuResourceKey, memoryResourceKey, minGPUCardsKey,
    minGPUMemoryMiBKey, memoryMiBKey, cpuCoresKey } from "../utils/rebalance";

const options: SelectProps['options'] = [
    { value: 'arm64', label: 'arm64' },
    { value: 'amd64', label: 'amd64' },
];

export default function NodeTemplateConfiguration() {
    const { clusterID } = useParams();
    const [rebalanceCfg, setRebalanceCfg] = useState<ClusterRebalanceConfiguration>();

    const [generalNodeProvisionInstanceFamily, setGeneralNodeProvisionInstanceFamily] = useState("");
    const latestGeneralNodeProvisionInstanceFamily = useRef(generalNodeProvisionInstanceFamily);

    const [instanceArch, setInstanceArch] = useState<string[]>([]);
    const latestInstanceArch = useRef(instanceArch);

    const [instanceTags, setInstanceTags] = useState("");
    const latestInstanceTags = useRef(instanceTags);

    const [systemDiskSizeGiB, setSystemDiskSizeGiB] = useState(0);
    const latestSystemDiskSizeGiB = useRef(systemDiskSizeGiB);

    const [extraAllocationCPUMCores, setExtraAllocationCPUCores] = useState(0);
    const latestExtraAllocationCPUMCores = useRef(extraAllocationCPUMCores);

    const [extraAllocationMemoryMiB, setExtraAllocationMemoryMiB] = useState(0);
    const latestExtraAllocationMemoryMiB = useRef(extraAllocationMemoryMiB);

    const [generalNodeProvisionMinCPUCores, setGeneralNodeProvisionMinCPUCores] = useState(0);
    const latestGeneralNodeProvisionMinCPUCores = useRef(generalNodeProvisionMinCPUCores);

    const [generalNodeProvisionMaxCPUCores, setGeneralNodeProvisionMaxCPUCores] = useState(0);
    const latestGeneralNodeProvisionMaxCPUCores = useRef(generalNodeProvisionMaxCPUCores);

    const [generalNodeProvisionMinMemoryMiB, setGeneralNodeProvisionMinMemoryMiB] = useState(0);
    const latestGeneralNodeProvisionMinMemoryMiB = useRef(generalNodeProvisionMinMemoryMiB);

    const [generalNodeProvisionMaxMemoryMiB, setGeneralNodeProvisionMaxMemoryMiB] = useState(0);
    const latestGeneralNodeProvisionMaxMemoryMiB = useRef(generalNodeProvisionMaxMemoryMiB);

    const [gpuNodeProvisionMinGPUCards, setGPUNodeProvisionMinGPUCards] = useState(0);
    const latestGPINodeProvisionMinGPUCards = useRef(gpuNodeProvisionMinGPUCards);

    const [gpuNodeProvisionMinGPUMemoryMiB, setGPUNodeProvisionMinGPUMemoryMiB] = useState(0);
    const latestGPUNodeProvisionMinGPUMemoryMiB = useRef(gpuNodeProvisionMinGPUMemoryMiB);

    const [gpuNodeProvisionInstanceFamily, setGPUNodeProvisionInstanceFamily] = useState("");
    const latestGPUNodeProvisionInstanceFamily = useRef(gpuNodeProvisionInstanceFamily);

    const [button, setButton] = useState<ReactNode>()

    useEffect(() => {
        latestGeneralNodeProvisionInstanceFamily.current = generalNodeProvisionInstanceFamily;
        latestGeneralNodeProvisionMinCPUCores.current = generalNodeProvisionMinCPUCores;
        latestGeneralNodeProvisionMaxCPUCores.current = generalNodeProvisionMaxCPUCores;
        latestGeneralNodeProvisionMinMemoryMiB.current = generalNodeProvisionMinMemoryMiB;
        latestGeneralNodeProvisionMaxMemoryMiB.current = generalNodeProvisionMaxMemoryMiB;
        latestGPINodeProvisionMinGPUCards.current = gpuNodeProvisionMinGPUCards;
        latestGPUNodeProvisionMinGPUMemoryMiB.current = gpuNodeProvisionMinGPUMemoryMiB;
        latestGPUNodeProvisionInstanceFamily.current = gpuNodeProvisionInstanceFamily;
        latestExtraAllocationCPUMCores.current = extraAllocationCPUMCores;
        latestExtraAllocationMemoryMiB.current = extraAllocationMemoryMiB;
        latestSystemDiskSizeGiB.current = systemDiskSizeGiB;
        latestInstanceTags.current = instanceTags;
        latestInstanceArch.current = instanceArch;
    }, [generalNodeProvisionMinCPUCores, generalNodeProvisionMinMemoryMiB,
        generalNodeProvisionMaxCPUCores, generalNodeProvisionMaxMemoryMiB, generalNodeProvisionInstanceFamily,
        gpuNodeProvisionMinGPUCards, gpuNodeProvisionMinGPUMemoryMiB, gpuNodeProvisionInstanceFamily,
        extraAllocationCPUMCores, extraAllocationMemoryMiB, systemDiskSizeGiB,
        instanceTags, instanceArch]);

    useEffect(() => {
        async function fetchClusterRebalanceConfiguration() {
            if (!clusterID) {
                return;
            }
            const cfg = await rebalance.getRebalanceConfiguration(clusterID);
            if (cfg.code !== 200) {
                console.error("Failed to fetch cluster costs optimization:", cfg.message);
                return;
            }

            setRebalanceCfg(cfg.data!);
            setGeneralNodeProvisionInstanceFamily(extractNodeProvisionInstanceFamily(cfg.data?.generalNodePoolSpec!));
            setGeneralNodeProvisionMinCPUCores(extractGeneralNodeProvisionMinCPUCores(cfg.data?.generalNodePoolSpec!));
            setGeneralNodeProvisionMaxCPUCores(extractGeneralNodeProvisionMaxCPUCores(cfg.data?.generalNodePoolSpec!));
            setGeneralNodeProvisionMinMemoryMiB(extractGeneralNodeProvisionMinMemoryMiB(cfg.data?.generalNodePoolSpec!));
            setGeneralNodeProvisionMaxMemoryMiB(extractGeneralNodeProvisionMaxMemoryMiB(cfg.data?.generalNodePoolSpec!));
            setSystemDiskSizeGiB(extractGeneralNodeProvisionSystemDiskSizeGiB(cfg.data?.ec2NodeClassSpec!));
            setExtraAllocationCPUCores(extractExtraResourceAllocation(cpuResourceKey, cfg.data?.generalNodePoolSpec!));
            setExtraAllocationMemoryMiB(extractExtraResourceAllocation(memoryResourceKey, cfg.data?.generalNodePoolSpec!));
            setInstanceTags(extraNodeProvisionInstanceTags(cfg.data?.ec2NodeClassSpec!));
            setInstanceArch(extractGeneralNodeProvisionInstanceArchitecture(cfg.data?.generalNodePoolSpec!));

            setGPUNodeProvisionMinGPUCards(extractGPUNodeProvisionMinGPUCards(cfg.data?.gpuNodePoolSpec!));
            setGPUNodeProvisionMinGPUMemoryMiB(extractGPUNodeProvisionMinGPUMemoryMiB(cfg.data?.gpuNodePoolSpec!));
            setGPUNodeProvisionInstanceFamily(extractNodeProvisionInstanceFamily(cfg.data?.gpuNodePoolSpec!)); 
        }
        fetchClusterRebalanceConfiguration();
    }, [clusterID])

    const spin = (
        <div className="h-8 w-32 absolute right-0 bottom-0 flex flex-row justify-center items-center">
            <CloudPilotSpin />
        </div>
    );

    const updateRebalanceConfiguration = async () => {
        let updatedRebalanceCfg = rebalanceCfg;
        if (!updatedRebalanceCfg) {
            return;
        }

        updatedRebalanceCfg.generalNodePoolSpec = configNodeProvisionMinValues(latestGeneralNodeProvisionMinCPUCores.current, cpuCoresKey, updatedRebalanceCfg.generalNodePoolSpec);
        updatedRebalanceCfg.generalNodePoolSpec = configNodeProvisionMaxValues(latestGeneralNodeProvisionMaxCPUCores.current, cpuCoresKey, updatedRebalanceCfg.generalNodePoolSpec);
        updatedRebalanceCfg.generalNodePoolSpec = configNodeProvisionMinValues(latestGeneralNodeProvisionMinMemoryMiB.current, memoryMiBKey, updatedRebalanceCfg.generalNodePoolSpec);
        updatedRebalanceCfg.generalNodePoolSpec = configNodeProvisionMaxValues(latestGeneralNodeProvisionMaxMemoryMiB.current, memoryMiBKey, updatedRebalanceCfg.generalNodePoolSpec);
        updatedRebalanceCfg.generalNodePoolSpec = configNodeProvisionInstanceFamily(latestGeneralNodeProvisionInstanceFamily.current, updatedRebalanceCfg.generalNodePoolSpec);
        updatedRebalanceCfg.generalNodePoolSpec = configNodeProvisionInstanceArch(latestInstanceArch.current, updatedRebalanceCfg.generalNodePoolSpec);

        updatedRebalanceCfg.gpuNodePoolSpec = configNodeProvisionMinValues(latestGPINodeProvisionMinGPUCards.current, minGPUCardsKey, updatedRebalanceCfg.gpuNodePoolSpec);
        updatedRebalanceCfg.gpuNodePoolSpec = configNodeProvisionMinValues(latestGPUNodeProvisionMinGPUMemoryMiB.current, minGPUMemoryMiBKey, updatedRebalanceCfg.gpuNodePoolSpec);
        updatedRebalanceCfg.gpuNodePoolSpec = configNodeProvisionInstanceFamily(latestGPUNodeProvisionInstanceFamily.current, updatedRebalanceCfg.gpuNodePoolSpec);
        updatedRebalanceCfg.generalNodePoolSpec = configExtraAllocation(cpuResourceKey, latestExtraAllocationCPUMCores.current, updatedRebalanceCfg.generalNodePoolSpec);
        updatedRebalanceCfg.generalNodePoolSpec = configExtraAllocation(memoryResourceKey, latestExtraAllocationMemoryMiB.current, updatedRebalanceCfg.generalNodePoolSpec);

        updatedRebalanceCfg.ec2NodeClassSpec = configNodeProvisionSystemDiskSize(latestSystemDiskSizeGiB.current, updatedRebalanceCfg.ec2NodeClassSpec);
        updatedRebalanceCfg.ec2NodeClassSpec = configNodeProvisionInstanceTags(latestInstanceTags.current, updatedRebalanceCfg.ec2NodeClassSpec);

        setButton(spin);
        const cfg = await rebalance.updateRebalanceConfiguration(clusterID!, updatedRebalanceCfg);
        if (cfg.code !== 200) {
            console.error("Failed to update cluster costs optimization:", cfg.message);
            return;
        }
        window.location.reload();
    };

    const saveButton = (
        <Button className="h-8 w-32 absolute right-0 bottom-0" type="primary" onClick={updateRebalanceConfiguration} >Save</Button>
    );

    if (!rebalanceCfg) {
        return <loading.RequestLoading />
    }

    if (!button) {
        setButton(saveButton);
    }

    const handleChange = (value: string[]) => {
        setInstanceArch(value);
    };

    return (
        <Space direction="vertical" size="middle" style={{ display: "flex" }}>
            <div className="w-full h-full min-w-[750px]">
                <div className="w-full bg-white">
                    <div className="flex flex-col bg-white rounded-lg p-6">
                        <div className="flex flex-row justify-between w-full">
                            <div className="flex flex-row space-x-8">
                                <div className="flex flex-col ">
                                    <div className="flex flex-row items-center">
                                        <h1 className="text-lg font-bold">Node Template Configuration</h1>
                                    </div>
                                    <p>Configure the provisioned node constraints. <span className="font-bold text-red-500">Any changes will be effective immediately.</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full bg-white p-6">
                    <div className="flex flex-row items-center border-b-[1px]">
                        <FontAwesomeIcon icon={faMicrochip} color="#1677ff" size="lg"/>
                        <h1 className="text-base ml-2 font-bold">General Node Provision configuration</h1>
                    </div>
                    <div className="w-full flex flex-row relative mt-6">
                        <div className="w-80 flex flex-col mr-10">
                            <span className="font-bold">Instance Family</span>
                            <span>The target instance family, like t3, m5 and so on, split by comma.</span>
                        </div>
                        <Input defaultValue={generalNodeProvisionInstanceFamily} className="w-80 h-8 flex items-center absolute bottom-0 left-96" onChange={(e) => setGeneralNodeProvisionInstanceFamily(e.target.value)} />
                    </div>
                    <div className="w-full flex flex-row relative mt-6">
                        <div className="w-80 flex flex-col mr-10">
                            <span className="font-bold">Instance Arch</span>
                            <span>The target instance architecture, if the instance family is configured, this field will be ignored.</span>
                        </div>
                        <div className="w-80 h-8 flex items-center absolute bottom-0 left-96">
                            <Select
                                mode="multiple"
                                allowClear
                                style={{ width: '100%' }}
                                placeholder="Please select the architecture"
                                defaultValue={instanceArch}
                                onChange={handleChange}
                                options={options}/>
                        </div>
                    </div>
                    <div className="w-full flex flex-row relative mt-6">
                        <div className="w-80 flex flex-col mr-10">
                            <span className="font-bold">Instance Tags</span>
                            <span>Each provisioned node will have the configured tags, formatted as key1=value1,key2=value2.</span>
                        </div>
                        <Input defaultValue={instanceTags} className="w-80 h-8 flex items-center absolute bottom-0 left-96" onChange={(e) => setInstanceTags(e.target.value)}  />
                    </div>
                    <div className="w-full flex flex-row relative mt-6">
                        <div className="w-80 flex flex-col mr-10">
                            <span className="font-bold">System Disk Size(GiB)</span>
                            <span>Each provisioned node's system storage size, default to be 20 GiB.</span>
                        </div>
                        <InputNumber defaultValue={systemDiskSizeGiB} className="w-80 h-8 flex items-center absolute bottom-0 left-96" onChange={(e) => setSystemDiskSizeGiB(e!)} />
                    </div>
                    <div className="w-full flex flex-row relative mt-6">
                        <div className="w-80 flex flex-col mr-10">
                            <span className="font-bold">Extra CPU Allocation(mCore)</span>
                            <span>Each provisioned node will have extra CPU allocation, used only for burstable pods.</span>
                        </div>
                        <InputNumber defaultValue={extraAllocationCPUMCores} className="w-80 h-8 flex items-center absolute bottom-0 left-96" onChange={(e) => setExtraAllocationCPUCores(e!)} />
                    </div>
                    <div className="w-full flex flex-row relative mt-6">
                        <div className="w-80 flex flex-col mr-10">
                            <span className="font-bold">Extra Memory Allocation(MiB)</span>
                            <span>Each provisioned node will have extra Memory allocation, used only for burstable pods.</span>
                        </div>
                        <InputNumber defaultValue={extraAllocationMemoryMiB} className="w-80 h-8 flex items-center absolute bottom-0 left-96" onChange={(e) => setExtraAllocationMemoryMiB(e!)} />
                    </div>
                    <div className="w-full flex flex-row relative mt-6">
                        <div className="w-80 flex flex-col mr-10">
                            <span className="font-bold">Min CPU(Core)</span>
                            <span>Each provisioned node's CPU will be greater than(<span className="font-bold">&gt;</span>) this value. A value of 0 means unlimited.</span>
                        </div>
                        <InputNumber defaultValue={generalNodeProvisionMinCPUCores} className="w-80 h-8 flex items-center absolute bottom-0 left-96" onChange={(e) => setGeneralNodeProvisionMinCPUCores(e!)} />
                    </div>
                    <div className="w-full flex flex-row relative mt-6">
                        <div className="w-80 flex flex-col mr-10">
                            <span className="font-bold">Max CPU(Core)</span>
                            <span>Each provisioned node's CPU will be less than(<span className="font-bold">&lt;</span>) this value. A value of 0 means unlimited.</span>
                        </div>
                        <InputNumber defaultValue={generalNodeProvisionMaxCPUCores} className="w-80 h-8 flex items-center absolute bottom-0 left-96" onChange={(e) => setGeneralNodeProvisionMaxCPUCores(e!)} />
                    </div>
                    <div className="w-full flex flex-row relative mt-6">
                        <div className="w-80 flex flex-col mr-10">
                            <span className="font-bold">Min Memory(MiB)</span>
                            <span>Each provisioned nodes' Memory will be greater than(<span className="font-bold">&gt;</span>) than this value. A value of 0 means unlimited.</span>
                        </div>
                        <InputNumber defaultValue={generalNodeProvisionMinMemoryMiB} className="w-80 h-8 flex items-center absolute bottom-0 left-96" onChange={(e) => setGeneralNodeProvisionMinMemoryMiB(e!)} />
                    </div>
                    <div className="w-full flex flex-row relative mt-6">
                        <div className="w-80 flex flex-col mr-10">
                            <span className="font-bold">Max Memory(MiB)</span>
                            <span>Each provisioned node's Memory will be less than(<span className="font-bold">&lt;</span>) than this value. A value of 0 means unlimited.</span>
                        </div>
                        <InputNumber defaultValue={generalNodeProvisionMaxMemoryMiB} className="w-80 h-8 flex items-center absolute bottom-0 left-96" onChange={(e) => setGeneralNodeProvisionMaxMemoryMiB(e!)} />
                    </div>
                    {/* <div className="w-full flex flex-row relative mt-6">
                        <div className="w-80 flex flex-col mr-10">
                            <span className="font-bold">Over Provisioning CPU Percentage</span>
                            <span>CloudPilot AI will provide a more specific percentage of CPU for workload requests.</span>
                        </div>
                        <InputNumber defaultValue={0}  addonAfter="%" className="w-80 h-8 flex items-center absolute bottom-0 left-96" />
                    </div>
                    <div className="w-full flex flex-row relative mt-6">
                        <div className="w-80 flex flex-col mr-10">
                            <span className="font-bold">Over Provisioning Memory Percentage</span>
                            <span>CloudPilot AI will provide a more specific percentage of Memory for workload requests.</span>
                        </div>
                        <InputNumber defaultValue={0}  addonAfter="%" className="w-80 h-8 flex items-center absolute bottom-0 left-96" />
                    </div> */}
                </div>
                <div className="w-full bg-white p-6">
                    <div className="flex flex-row items-center border-b-[1px]">
                        <FontAwesomeIcon icon={faBrain} color="#1677ff" size="lg"/>
                        <h1 className="text-base ml-2 font-bold">GPU Node Provision configuration</h1>
                    </div>
                    <div className="w-full flex flex-row relative mt-6">
                        <div className="w-80 flex flex-col mr-10">
                            <span className="font-bold">Instance Family</span>
                            <span>The target instance family, like g3, g4 and so on, split by comma.</span>
                        </div>
                        <Input defaultValue={gpuNodeProvisionInstanceFamily} className="w-80 h-8 flex items-center absolute bottom-0 left-96" onChange={(e) => setGPUNodeProvisionInstanceFamily(e.target.value)} />
                    </div>
                    <div className="w-full flex flex-row relative mt-6">
                        <div className="w-80 flex flex-col mr-10">
                            <span className="font-bold">Min Graphic Cards(Card)</span>
                            <span>Each provisioned nodes' GPU cards will be greater(<span className="font-bold text-red-500">&gt;</span>) than this value.</span>
                        </div>
                        <InputNumber defaultValue={gpuNodeProvisionMinGPUCards} className="w-80 h-8 flex items-center absolute bottom-0 left-96" onChange={(e) => setGPUNodeProvisionMinGPUCards(e!)} />
                    </div>
                    <div className="w-full flex flex-row relative mt-6">
                        <div className="w-80 flex flex-col mr-10">
                            <span className="font-bold">Min Graphic Memory(MiB)</span>
                            <span>Each provisioned nodes' Graphic Memroy will be greater(<span className="font-bold text-red-500">&gt;</span>) than this value.</span>
                        </div>
                        <InputNumber defaultValue={gpuNodeProvisionMinGPUMemoryMiB} className="w-80 h-8 flex items-center absolute bottom-0 left-96" onChange={(e) => setGPUNodeProvisionMinGPUMemoryMiB(e!)} />
                    </div>
                </div>
                <div className="w-full bg-white p-6 rounded-b-lg">
                    <div className="w-full h-14 border-t-[1px] relative">
                        {button}
                    </div>
                </div>
            </div>
        </Space>
    )
}