import { requests } from "../utils/fetch"
import { Response } from "../type/common"
import { server } from "../config/props"
import { ClusterBilling } from "../type/clusterbilling"

async function unregisterCluster(clusterID: string): Promise<Response<string>> {
    return requests.del(`${server}/api/v1/clusters/${clusterID}`)
}

async function getUninstallSH(clusterID: string): Promise<Response<string>> {
    return requests.get(`${server}/api/v1/clusters/${clusterID}/uninstall/sh`)
}

async function getUpgradeSH(clusterID: string): Promise<Response<string>> {
    return requests.get(`${server}/api/v1/clusters/${clusterID}/upgrade/sh`)
}

async function getRestoreSH(clusterID: string): Promise<Response<string>> {
    return requests.get(`${server}/api/v1/clusters/${clusterID}/restore/sh`)
}

async function getClusterBilling(clusterID: string): Promise<Response<ClusterBilling>> {
    return requests.get(`${server}/api/v1/clusters/${clusterID}/billing`)
}

export const cluster = {
    unregisterCluster,
    getUninstallSH,
    getClusterBilling,
    getRestoreSH,
    getUpgradeSH,
}